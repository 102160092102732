import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  isRouteErrorResponse,
  useLoaderData,
  useRouteLoaderData,
  useLocation
} from "@remix-run/react";
import type {ClientLoaderFunctionArgs} from "@remix-run/react";
import { json } from "@remix-run/node";
import { useEffect, useState, useRef } from "react";
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import tailwindStyle from "~/tailwind.css?url";
import rootStyle from "~/styles/root_styles.css?url";
import autoCompleteStyle from '@algolia/autocomplete-theme-classic/dist/theme.min.css?url';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Navbar from "./components/navbar";
import * as gtag from "./client/gtags.client";

export const links = () => [
  { rel: "icon", href: "/favicon.ico?v=1" },
  { rel: "stylesheet", href: tailwindStyle, as: 'style' },
  { rel: "stylesheet", href: rootStyle, as: 'style' },
  { rel: "stylesheet", href: autoCompleteStyle, as: 'style' }
];

// TODO: remove this
const rrPassCodeLocalStorageKey = 'rr-client-passcode';
const rrPassCode = '2024';

// Load the GA tracking id from the .env
export const loader = async () => {
  return json({ gaTrackingId: process.env.GA_TRACKING_ID, passCodeCheck: process.env.PASSCODE_CHECK === 'true' });
};

export function Layout({ children }: { children: React.ReactNode }) {
  // useEffect error boundary was not working, so use state instead:
  // https://github.com/facebook/react/issues/18841
  const [errorPasscode, setErrorPasscode] = useState<boolean>(true);
  const initialized = useRef(false); //https://stackoverflow.com/a/75126229

  const location = useLocation();
  // https://remix.run/docs/en/main/file-conventions/root#layout-export
  // https://github.com/remix-run/remix/issues/8951
  const loaderData = useRouteLoaderData<typeof loader>("root");
  const gaTrackingId = loaderData?.gaTrackingId;
  const passCodeCheck = loaderData?.passCodeCheck;

  useEffect(() => {
    if (initialized.current || !passCodeCheck) {
      return;
    }
    
    initialized.current = true
    const passCode = localStorage.getItem(rrPassCodeLocalStorageKey) ?? prompt("Passcode?");
    
    if(passCode !== rrPassCode) {
      setErrorPasscode(true);
    } else {
      localStorage.setItem(rrPassCodeLocalStorageKey, rrPassCode);
      setErrorPasscode(false);
    }
 }, []);

  useEffect(() => {
    // console.log(`gaTrackingId: ${gaTrackingId}`)
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  return (
    <html lang="en" className="text-rr-primary">
      <head>
        {/* https://github.com/remix-run/examples/blob/main/google-analytics/app/root.tsx */}
        {/* process.env.NODE_ENV === "development" ||  */}
        {process.env.NODE_ENV === "development" || !gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `
              }}
            />
          </>
        )}

        {/* https://developers.google.com/search/docs/appearance/site-names#website */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context" : "https://schema.org",
              "@type" : "WebSite",
              "name" : "Related Repos",
              "url" : "https://relatedrepos.com/"
            }`}}
        />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div style={{ maxWidth: "1200px", margin: "auto" }}>
          { (passCodeCheck && errorPasscode) ? 
          <div>Not authenticated</div> :
          <>
            <Navbar />
            <main className="pl-2 pr-2">
              {children}
              
              {/* remove? */}
              {/* <a id="feedback-button" className="inline-flex items-center gap-x-1.5 background-button-white rounded-md ring-1 ring-inset ring-gray-300 px-3 py-2 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href="https://github.com/RelatedRepos/discussion/discussions/new?category=general-feedback" target="_blank">
                <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-gray-400" />
                <span className="text-rr-button-white-primary hidden md:inline">Feedback</span>
              </a> */}
            </main>
          </>
          }
        </div>

        <ToastContainer />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}

export function ErrorBoundary() {
  const error: any = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </>
    );
  }

  return (
    <>
      <h1>Error!</h1>
      <p>{error?.message ?? "Unknown error"}</p>
    </>
  );
}